import {Component} from 'react'
import ApiService from "./ApiService";

class DataService extends Component {
    static naturalShortDate(date_str, locale) {
        let month_names = {
            names_en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            names_es: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            names_pt: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        };

        date_str = date_str.split('-');
        let year = date_str[0];
        let month = date_str[1];
        let day = date_str[2];

        let month_name = month_names['names_' + locale][parseInt(month)-1];
        return day + month_name + year.substr(-2);
    }

    static naturalShortDateByMonthAndYear(month, year, locale) {
        let month_names = {
            names_en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            names_es: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            names_pt: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        };

        let month_name = month_names['names_' + locale][month-1];
        year = year.toString().substr(-2);

        return month_name + year;
    }

    static naturalLongDateByMonthAndYear(month, year, locale) {
        let month_names = {
            names_en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            names_es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            names_pt: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
        };

        let month_name = month_names['names_' + locale][month-1];
        return month_name + " " + year;
    }

    static naturalShortMonthNames(month, locale) {
        let month_names = {
            names_en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            names_es: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            names_pt: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        };

        let month_name = month_names['names_' + locale][month-1];
        return month_name;
    }

    static naturalMonthNames(month, locale) {
        let month_names = {
            names_en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            names_es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            names_pt: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],            
        };

        let month_name = month_names['names_' + locale][month-1];
        return month_name;
    } 

    static naturalLongDate(date_str, locale) {
        let month_names = {
            names_en: ['January', 'February', 'March', 'April', 'May', 'Jun', 'July', 'August', 'September', 'October', 'November', 'December'],
            names_es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            names_pt: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        };

        date_str = date_str.split('-');
        let year = date_str[0];
        let month = date_str[1];
        let day = date_str[2];

        let month_name = month_names['names_' + locale][parseInt(month)-1];

        if (locale === 'en') return month_name + ' ' + day + ', ' + year;
        if (locale === 'pt') return day + ' de ' + month_name.toLowerCase() + ' de ' + year;
        return day + ' de ' + month_name.toLowerCase() + ' del ' + year;
    }

    static naturalLongCurrentDate(locale) {
        return DataService.naturalLongDate(
            DataService.formatDateYMD(new Date()), 
            locale
        );
    }

    static occidentalDate(date_str) {
        if (!date_str) return null;
        date_str = date_str.substr(0, 10);
        date_str = date_str.split('-');

        let year = date_str[0];
        let month = date_str[1];
        let day = date_str[2];

        return day + "/" + month + "/" + year;
    }  

    static usaDate(date_str) {
        if (!date_str) return null;
        date_str = date_str.substr(0, 10);
        date_str = date_str.split('-');

        let year = date_str[0];
        let month = date_str[1];
        let day = date_str[2];

        return month + "/" + day + "/" + year;
    } 

    static occidentalDateTime(date_str) {
        if (!date_str) return null;

        let date = new Date(date_str);

        let day = date.getDate();
        if (day<10) {day = "0" + day}

        let month = date.getMonth() +1;
        if (month<10) {month = "0" + month}

        let year = date.getFullYear();

        let tt = 'am';

        let hours = date.getHours();
        if (hours > 12) {
            hours -= 12;
            tt = 'pm';
        }

        if (hours<10) {hours = "0" + hours}

        let minutes = date.getMinutes();
        if (minutes<10) {minutes = "0" + minutes}

        let seconds = date.getSeconds();
        if (seconds<10) {seconds = "0" + seconds}

        return day + "/" + month + "/" + year + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + tt;
    }

    static occidentalTime(date_str) {
        if (!date_str) return null;

        let date = new Date(date_str);
        let tt = 'am';

        let hours = date.getHours();
        if (hours > 12) {
            hours -= 12;
            tt = 'pm';
        }

        if (hours<10) {hours = "0" + hours}

        let minutes = date.getUTCMinutes();
        if (minutes<10) {minutes = "0" + minutes}

        let seconds = date.getUTCSeconds();
        if (seconds<10) {seconds = "0" + seconds}

        return hours + ':' + minutes + ':' + seconds + ' ' + tt;
    }

    static occidentalTimeFromDate(date) {
        if (!date) return null;

        let tt = 'am';

        let hours = date.getUTCHours();
        if (hours > 12) {
            hours -= 12;
            tt = 'pm';
        }

        if (hours<10) {hours = "0" + hours}

        let minutes = date.getUTCMinutes();
        if (minutes<10) {minutes = "0" + minutes}

        let seconds = date.getUTCSeconds();
        if (seconds<10) {seconds = "0" + seconds}

        return hours + ':' + minutes + ':' + seconds + ' ' + tt;
    }

    static naturalDateByMonthAndYear(month, year, locale) {
        let month_names = {
            names_en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            names_es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            names_pt: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],            
        };

        let month_name = month_names['names_' + locale][month-1];
        return month_name + " " + year;
    }

    static formatDateYMD(date) {
        let day = date.getDate();
        if (day<10) {day = "0" + day}

        let month = date.getMonth() +1;
        if (month<10) {month = "0" + month}

        let year = date.getFullYear();

        return year + "-" + month + "-" + day;
    }

    static numberFormat(number) {
        if (number == null) return number;
        if (number === '') return number;
        if (number === 0) return '0.00';

        number = number.toString().split(".");
        number[0] = number[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        return number.join(".");
    }

    static capitalize(value) {
        if (!value) return value;

        value =  value.toString().replace('_', ' ')
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    static pluralize(singular) {
        if (singular === 'Feliz') return 'Felices';
        if (singular === 'Neutral') return 'Neutrales';
        if (singular === 'Triste') return 'Tristes';

        return singular;
    }

    static getDateFromYMD(ymd) {
        ymd = ymd.substr(0, 10);
        let date_parts = ymd.split('-');

        return new Date(date_parts[0], date_parts[1]-1, date_parts[2]);
    }

    static getFirstDateStr(month, year) {
        let date = new Date(year, month-1, 1);
        return DataService.formatDateYMD(date);
    }

    static getLastDateStr(month, year) {
        let date = new Date(year, month, 0);
        return DataService.formatDateYMD(date);
    }

    static getIntegerPercent(value, total) {
        if (total === 0) return 0;
        return (value/total*100).toFixed(2);
    }

    static truncate(text, size) {
        if (text.length <= size) return text;
        return text.substring(0, size) + '...';
    }

    static getDaysInMonthFromToday() {
        let current_date = new Date();

        let month = current_date.getMonth() +1;
        let year = current_date.getFullYear();
        let total_days = new Date(year, month, 0).getDate();
        let current_day = current_date.getDate();

        return [...Array(total_days - current_day + 1).keys()].map(x => x + current_day);
    }

    static daysUntilToday(date) {
        return Math.round((new Date() - date) / (1000 * 60 * 60 * 24));
    }

    static getNaturalShortDayOfWeekOfCurrentMonth(day, locale) {
        let names = {
            names_en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            names_es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
            names_pt: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        };

        let current_date = new Date();
        let month = current_date.getMonth() +1;
        let year = current_date.getFullYear();
        let day_of_week = new Date(year, month, day).getDay();
        return names['names_' + locale][day_of_week].toUpperCase();
    }

    static getDateYMDOnDayAndCurrentMonthYear(day) {
        let current_date = new Date();
        if (day<10) {day = "0" + day}

        let month = current_date.getMonth() +1;
        if (month<10) {month = "0" + month}

        let year = current_date.getFullYear();

        return year + "-" + month + "-" + day;
    }

    static loadFilterOptions(t, endpoint, set_state, set_filter_text) {
        set_filter_text(t("Searching..."));

        ApiService.requestEndpoint(t, window.env.CUSTOMS_BACKEND_RAILS_API, endpoint,
            (data) => {
                if (data['entities']) data = data['entities'];

                if (endpoint.includes('companies')) set_state( data.map((x) => { return { id: x.id, label: `${x.name}. KEY: ${x.key}, IRS/ISSN: ${x.irs_ssn}`} }) );
                else if (endpoint.includes('traffics')) set_state( data.map((x) => { return {id: x.id, label: `${x.number}. STATUS: ${x.traffic_status.name}, CUSTOMER: ${x.customer.name}`} }) );
                else set_state( data.map((x) => { return { id: x.id, label: `${x.code}   ${x.name}`} }) )

                if (data.length === 0) set_filter_text("0 " + t("records"))
            }
        );
    }

    static getCurrentTimeStamp() {
        return (new Date()).getTime();
    }

    static humanizeFileSizeBytes(file_size) {
        if (!file_size) return file_size;

        let metrics = ["B", "KB", "MB", "GB", "TB", "PB"];
        let um = metrics.shift();

        while (file_size >= 1024) {
            file_size = file_size/1024
            um = metrics.shift();0
        }

        return file_size.toFixed(2) + " " + um;
    }
}

export default DataService;
